
import React, { useEffect, useState } from 'react';
import { RevealContent, Image, Reveal } from 'semantic-ui-react';
import Aheader from './Aheader';
import { Link, useNavigate } from 'react-router-dom';
import Carousel from './Caro';
import Footer from './Footer';
import WOW from 'wowjs';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Login from './Login';
import Tab1 from './Tab1';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';

import axiosi from './axiosinstance';
import './all.css';
import 'animate.css/animate.min.css';
import _ from 'lodash';
import Button from '@mui/material/Button';
import Swal from 'sweetalert2';

import {
  TableRow,
  TableHeaderCell,
  TableHeader,
  TableCell,
  TableBody,
  Table,
} from 'semantic-ui-react';
import zIndex from '@mui/material/styles/zIndex';

import {
//   Table,
//   TableBody,
//   TableCell,
  TableContainer,
  TableHead,
//   TableRow,
  Paper,
} from '@mui/material';
import { styled } from '@mui/system';

const Acos = ({ cont, user, setUser }) => {
  const [cname, setCname] = useState('');
  const [desc, setDesc] = useState('');
  const [pix, setPix] = useState();
  const [show, setShow] = useState(false);
  const [ll, setLl] = useState(true);
  const [llb, setLlb] = useState(true);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    desc: '',
    name:'',
    
    // Add more fields as needed
  });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [courses, setCourses] = useState();
  let [checkCalled, setCheck] = useState(false);

  const [image, setImage] = useState('');
  const [price, setPrice] = useState(0);

  function exampleReducer(state, action) {
    switch (action.type) {
      case 'CHANGE_SORT':
        if (state.column === action.column) {
          return {
            ...state,
            data: state.data.slice().reverse(),
            direction:
              state.direction === 'ascending' ? 'descending' : 'ascending',
          };
        }

        return {
          column: action.column,
          data: _.sortBy(state.data, [action.column]),
          direction: 'ascending',
        };
      default:
        throw new Error();
    }
  }
  const [state, dispatch] = React.useReducer(exampleReducer, {
    column: null,
    data: courses,
    direction: null,
  });
  const { column, data, direction } = state;

  useEffect(() => {
    const Check = async () => {
      try {
        await axios.get(`${cont.api}getcoursesa`,{
              headers: {
                'userid': 'jhgfdfghj'             
              }}).then((res) => {
          if (res.data.success) {
            // setFirst(false);

            if (res.data.courses) {
              //   alert(res.data.data.latestloginplayertime);
              setCourses( res.data.courses);
              setPix((prev) => res.data.pix);
              const oi = res.data.pix;
              setImage((prev) => oi[0].image);
              // setFirst(false);

              // navigate('/admin');
            }
          } else {
            navigate('/');
          }
        });
      } catch (error) {
        // Handle error if needed
        console.error('Error:', error);
      }
      setLl(false);
    };

    // Check if jwtt and first are truthy
    if (!checkCalled) {
      // Call Check() if conditions are met
      Check();

      // Set the flag to true to indicate that Check() has been called
      setCheck(!checkCalled);
    }
  }, [courses, user, cont, checkCalled]);
  const Scourse = async (e) => {
    e.preventDefault();
    setLlb(false);

    // Create a new FormData object
    const formDataToSend = new FormData();

    // Iterate through the form data and append each field and value to FormData
    for (let key in formData) {
      formDataToSend.append(key, formData[key]);
    }
    formDataToSend.append('desc', desc);
    formDataToSend.append('image',image);
    formDataToSend.append('price',price);
    formDataToSend.append('name', cname);
    // formDataToSend.append('api', );
    

    

    try {
      await axios.post(`${cont.api}addcourse`, formDataToSend).then((res) => {
        if (res.data.success) {
          if (res.data.courses) {
            //   alert(res.data.data.latestloginplayertime);
            setCourses(() => res.data.courses);
            const oi = res.data.pix;
            setImage((prev) => oi[0].image);
            handleClose();

            // alert(res.data.user);

            Swal.fire({
              title: `New course added successfully`,
              
              icon: 'success',
            });
            
          } else {
            // Dalert(res.data.message);
            Swal.fire({
              title: `there was an error performing this action`,
              text: `Error`,
              icon: 'error',
            });
          }
        } else {
          // Dalert('pls check your network connection');
          Swal.fire({
            title: `error : error`,
            text: `error`,
            icon: 'error',
          });
          setLlb(true);
        }
      });
    } catch (error) {
      // Handle error if needed
      console.error('Error:', error);
    //   setLlb(true);
      // Dalert('pls check your network connection');
    }
    setLlb(true);

  };

  const Focus = async (cid) => {
    const course = courses.filter((el) => (el.cid === cid));
    navigate('/course', { state: course });
    
  };
  const Del = async (cid, name) => {
    async function Goon() {
        
      await axios.get(`${cont.api}delcourse/${cid}`).then((res) => {
        if (res.data.success) {
          // setFirst(false);

          if (res.data.courses) {
            //   alert(res.data.data.latestloginplayertime);
            setCourses(res.data.courses);
            const oi = res.data.pix;
            setImage((prev) => oi[0].image);
            Swal.fire({
              title: 'Deleted !',
              text: 'course  deleted successfully ! !',
              icon: 'success',
            });
          }
        } else {
          
          Swal.fire({
            title: 'Not Deleted!',
            text:'something went wrong deleting this file.',
            icon: 'error',
          });
        }
      });
    }
    Swal.fire({
      title: `Are you sure you want to delete ${name}'s (course) ?`,
      text: `You won't be able to revert this ! `,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        Goon();
      }
    });
  };
  const ResponsiveTableContainer = styled(TableContainer)({
    '@media (max-width: 600px)': {
      '& thead': {
        display: 'none',
      },
      '& tr': {
        display: 'block',
        marginBottom: '1rem',
      },
      '& td': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '&:before': {
          content: 'attr(data-label)',
          fontWeight: 'bold',
          marginRight: '1rem',
        },
      },
    },
  });
  return (
    <div className="px-1 app-container">
      <Aheader
        setShow={setShow}
        handleClose={handleClose}
        handleShow={handleShow}
        home={true}
        cont={cont}
        setUser={setUser}
        user={user}
        acourse={true}
      />
      <div>
        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Body>
            <div style={{ marginTop: '' }} className="form-sectionh fera">
              <div class="login-root">
                <div class="box-root flex-flex flex-direction--column stt">
                  <div class="box-root  flex-flex flex-direction--column fg-11 zz-9">
                    <div class="box-root  flex-flex flex-justifyContent--center">
                      <h1>
                        <a href="http://blog.stackfindover.com/" rel="dofollow">
                          {cont.name}
                        </a>
                      </h1>
                    </div>
                    <div class="formbg-outer">
                      <div class="formbg">
                        <div class="formbg-inner padding-horizontal--48">
                          <span class="padding-bottom--15">Add new course</span>
                          <form id="stripe-login" onSubmit={Scourse}>
                            <div class="field padding-bottom--24">
                              <label for="email">Course Title</label>
                              <input
                                type="text"
                                placeholder="e.g front-end development"
                                value={cname}
                                required
                                onChange={(e) => setCname(e.target.value)}
                                name="email"
                              />
                            </div>
                            <div class="field padding-bottom--24">
                              <label for="fee">Course Price</label>
                              <input
                                type="number"
                                placeholder="20000"
                                value={price}
                                required
                                onChange={(e) => setPrice(e.target.value)}
                                name="email"
                                id="fee"
                                min="1000"
                              />
                            </div>
                            <div class="field padding-bottom--24">
                              <label for="avatar">Avatar</label>
                              {/* <input
                                type="text"
                                placeholder=""
                                value={image}
                                required
                                onChange={(e) => setImage(e.target.value)}
                                name="image"
                                id="avatar"
                              /> */}
                              {/* <p>{image}</p> */}
                              <img
                                className="rounded text-center mx-3 mb-3"
                                style={{ objectFit: 'contain', width: '5vw' }}
                                src={image}
                                alt=""
                              />

                              <div className="row px-2 rolla py-2">
                                {pix && pix.length > 0
                                  ? pix.map((el, index) => (
                                      <div
                                        onClick={(e) =>
                                          setImage((prev) => el.image)
                                        }
                                        className="col-md-3 col-4  rounded text-center tayo cpp"
                                        key={index}
                                      >
                                        <img
                                          style={{
                                            objectFit: 'contain',
                                            width: '100%',
                                            height: '100%',
                                          }}
                                          src={el.image}
                                          alt=""
                                        />
                                      </div>
                                    ))
                                  : ''}
                              </div>
                            </div>
                            <div class="field padding-bottom--24">
                              <div class="grid--50-50">
                                <label for="password">Description</label>
                              </div>
                              <textarea
                                name="desc"
                                className="form-control"
                                onChange={(e) => setDesc(e.target.value)}
                                id=""
                                required
                                style={{ resize: 'none' }}
                              ></textarea>
                            </div>

                            {llb ? (
                              <div class="field padding-bottom--24">
                                <input
                                  type="submit"
                                  name="submit"
                                  value="Save"
                                />
                              </div>
                            ) : (
                              <div className="text-center">
                                <button
                                  type="button"
                                  className="btn btn-primary w-50 text-center clkbtn text-light bg-primary mx-auto dpapa "
                                >
                                  {/* <CircularProgress className="text-light blinking-text" /> */}
                                  <Spinner
                                    className="text-center"
                                    animation="border"
                                    variant="light"
                                  />
                                </button>
                              </div>
                            )}
                            <div class="field">
                              <div class="reset-pass">
                                <Link to="#">Forgot your password?</Link>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>

        <div class="container-xxl py-5">
          <div class="container">
            <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
              <h6 class="section-title bg-white text-center text-primary px-3">
                Courses
              </h6>
              <h1 class="mb-5">Admin Courses</h1>
            </div>
            <div className="mb-5">
              <Button variant="contained" color="primary" onClick={setShow}>
                Add course
              </Button>
            </div>
            {ll ? (
              <div className="text-center mx-auto mt-5">
                <Spinner
                  className="text-center"
                  animation="border"
                  variant="primary"
                />
              </div>
            ) : (
              <ResponsiveTableContainer
                component={Paper}
                class="row g-4 justify-content-center coco"
              >
                {courses && courses.length > 0 ? (
                  <Table sortable celled sticky className="text-center">
                    <TableHeader style={{ zIndex: 88 }} className="thh  mt-5">
                      <TableRow>
                        <TableHeaderCell
                          className="text-center"
                          sorted={column === 'name' ? direction : null}
                          onClick={() =>
                            dispatch({ type: 'CHANGE_SORT', column: 'name' })
                          }
                        >
                          S/N
                        </TableHeaderCell>
                        <TableHeaderCell
                          className="text-center"
                          sorted={column === 'name' ? direction : null}
                          onClick={() =>
                            dispatch({ type: 'CHANGE_SORT', column: 'name' })
                          }
                        >
                          Avatar
                        </TableHeaderCell>
                        <TableHeaderCell
                          className="text-center z-7"
                          sorted={column === 'name' ? direction : null}
                          onClick={() =>
                            dispatch({ type: 'CHANGE_SORT', column: 'name' })
                          }
                        >
                          Name
                        </TableHeaderCell>
                        <TableHeaderCell
                          sorted={column === 'age' ? direction : null}
                          onClick={() =>
                            dispatch({ type: 'CHANGE_SORT', column: 'age' })
                          }
                        >
                          Cost
                        </TableHeaderCell>
                        <TableHeaderCell
                          sorted={column === 'gender' ? direction : null}
                          onClick={() =>
                            dispatch({ type: 'CHANGE_SORT', column: 'gender' })
                          }
                        >
                          Date Added
                        </TableHeaderCell>

                        <TableHeaderCell
                          sorted={column === 'gender' ? direction : null}
                          onClick={() =>
                            dispatch({ type: 'CHANGE_SORT', column: 'gender' })
                          }
                        >
                          Revenue
                        </TableHeaderCell>
                        <TableHeaderCell
                          className="text-danger"
                          sorted={column === 'gender' ? direction : null}
                          onClick={() =>
                            dispatch({ type: 'CHANGE_SORT', column: 'gender' })
                          }
                        >
                          Delete
                        </TableHeaderCell>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {courses.map(
                        (
                          {
                            dprice,
                            deployed,
                            cid,
                            dprofit,
                            image,
                            createddate,
                            name,
                            students,
                          },
                          index
                        ) => (
                          <TableRow key={cid} style={{ position: 'relative' }}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                              <img className="imm rounded" src={image} alt="" />
                            </TableCell>
                            <TableCell
                              className="tidam fs-5 cpp"
                              onClick={(e) => Focus(cid)}
                            >
                              {name} 
                              {deployed ? (
                                <i className="fa fa-check text-success"></i>
                              ) : (
                                <i className="fa fa-times text-danger"></i>
                              )}{' '}
                              &nbsp; <small>({students} stdnt(s))</small>
                            </TableCell>
                            <TableCell>{dprice}</TableCell>
                            <TableCell>{createddate}</TableCell>
                            <TableCell>{dprofit}</TableCell>
                            <TableCell
                              className="text-danger"
                              onClick={(e) => Del(cid, name)}
                            >
                              <i className="fa fa-trash"></i>
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                ) : (
                  <h4 className="text-danger text-center">
                    {' '}
                    No registered course yet{' '}
                  </h4>
                )}
              </ResponsiveTableContainer>
            )}
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default Acos
