import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UserProvider } from '../src/components/usercontext';
import {
  HashRouter as Router,
  
} from 'react-router-dom';

// import './styles/animate.min.css';
// import './styles/normalize.css';
// import './styles/meanmenu.css';
// import './styles/owl.carousel.min.css';
// import './styles/slick.css';
// import './styles/jquery-ui.css';
// import './styles/nice-select.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <UserProvider>
    <Router>
    <App />
    </Router>
  </UserProvider>
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
