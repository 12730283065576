import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import Swal from 'sweetalert2';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
// import { FaAngleDoubleRight } from 'react-icons/fa';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Dropdown from 'react-bootstrap/Dropdown';
import Snackbar from '@mui/material/Snackbar';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
const Header = ({
  handleShow,
  handleClose,
  setShow,
  show,
  about,
  home,
  trans,
  user,
  cont,
  setUser,
  stud,acourse,asettings,atrans,
}) => {
  const [snack, setSnack] = useState('');
  const navigate = useNavigate();

  function setItemWithExpiry(key, value, ttl) {
    const now = new Date();

    // `ttl` is time to live in milliseconds (5 minutes = 5 * 60 * 1000)
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    };

    localStorage.setItem(key, JSON.stringify(item));
  }
  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal, open } = state;

  const handleClick = (newState) => () => {
    setState({ ...newState, open: true });
  };
  const Logout = () => {
    localStorage.removeItem('userid');
    setSnack('Account signed out !');
    setUser(() => null);

    handleClick({ vertical: 'top', horizontal: 'center' });
    // setSnack('');

    navigate('/');
  };

  return (
    <div className="position-sticky tpp">
      <nav className="navbar navbar-expand-lg tpp bg-white navbar-light shadow sticky-top p-0">
        <Link
          to="/"
          className="navbar-brand d-flex align-items-center px-4 px-lg-5"
        >
          <h1 className="m-0 text-primary">
            <i className="fa fa-book me-3"></i> {cont.name}
          </h1>
        </Link>
        <Dropdown className="d-md-none d-block">
          <Dropdown.Toggle variant="light" id="dropdown-basic">
            <span className="navbar-toggler-icon"></span>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item>
              <Link
                to="/admin"
                className={`${stud ? 'active' : ''} nav-item nav-link`}
              >
                Scholars
              </Link>
            </Dropdown.Item>
            <Dropdown.Item>
              <Link
                to="/acourses"
                className={`${acourse ? 'active' : ''} nav-item nav-link`}
              >
                Admin courses
              </Link>
            </Dropdown.Item>
            <Dropdown.Item>
              <Link
                to="/atrans"
                className={`${atrans ? 'active' : ''} nav-item nav-link`}
              >
                Transactions
              </Link>
            </Dropdown.Item>
            <Dropdown.Item>
              <Link
                to="support"
                className={`${asettings ? 'active' : ''} nav-item nav-link`}
              >
                <i className="fa fa-gear text-primary"></i> &nbsp; Site Settings
              </Link>
            </Dropdown.Item>
            <Dropdown.Item>
              <Link to="/" className="nav-item nav-link">
                <i className="fa fa-arrow-left"></i>
              </Link>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        

        <div className="align-items-center collapse navbar-collapse px-2" id="navbarCollapse">
          <div className="navbar-nav ms-auto p-4 p-lg-0">
            <Link
              to="/admin"
              className={`${stud ? 'active' : ''} nav-item nav-link`}
            >
              Scholars
            </Link>
            <Link
              to="/acourses"
              className={`${acourse ? 'active' : ''} nav-item nav-link`}
            >
              A courses
            </Link>
            <Link
              to="/atrans"
              className={`${trans ? 'active' : ''} nav-item nav-link`}
            >
              Transactions
            </Link>

            <Link
              to="support"
              className={`${asettings ? 'active' : ''} nav-item nav-link`}
            >
              <i className="fa fa-gear text-primary"></i> &nbsp; 
            </Link>
            <Link to="/" className="nav-item nav-link">
              <i className="fa fa-arrow-left"></i>
            </Link>
          </div>
          {/* <h2 className="tida">{user.name}</h2> &nbsp; */}
          <div
            onClick={Logout}
            className="btn btn-danger py-1 px-lg-2 d-none d-md-block"
          >
            <i className="fa fa-sign-out ms-3 text-light"></i> 
          </div>
        </div>
        
      </nav>
      
    </div>
  );
};

export default Header;
