import React from 'react'
import {Link} from 'react-router-dom'
// import '/css/other.css'

const Other = ({cont,setShow}) => {
  const [vidd, setVidd] = React.useState(false);

    const ed = [
      {
        name: 'Web development',
        image: '/images/my1.jpg',
        talk: 'The process of designing, coding, testing, and maintaining applications and systems. ',
      },
      {
        name: 'Cinematography',
        image: '/images/my2.jpg',
        talk: 'It involves the creative use of camera angles, lighting, and shot composition to tell a story',
      },
      {
        name: 'Data Structures',
        image: '/images/my3.jpg',
        talk: 'Fundamental concepts in computer science that involve organizing and storing data efficiently.',
      },
      {
        name: 'Research',
        image: '/images/my4.jpg',
        talk: 'Research is the systematic investigation and study of materials, sources, and data to establish facts and reach new conclusions. ',
      },
      
    ];
  return (
    <div>
      <div id="important" className="important">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="titlepage">
                <h2>
                  Some <strong className="yellow">important facts</strong>
                </h2>
                <span>
                  “We stand on the brink of a technology revolution that will
                  fundamentally alter the way we Live, Work, and Relate to one
                  another. In its scale, scope, and complexity, the
                  transformation will be unlike anything human kind has ever
                  experienced before.”
                </span>
                <sub>-Klaus Schwab Founder, World Economic Forum.</sub>
              </div>
            </div>
          </div>
        </div>
        <div className="important_bg">
          <div className="container">
            <div className="row">
              <div className="col col-xs-12">
                <div className="important_box">
                  <h3>200+</h3>
                  <span>Teachers</span>
                </div>
              </div>
              <div className="col col-xs-12">
                <div className="important_box">
                  <h3>20+</h3>
                  <span>Career paths</span>
                </div>
              </div>
              <div className="col col-xs-12">
                <div className="important_box">
                  <h3>50+</h3>
                  <span>Courses</span>
                </div>
              </div>
              <div className="col col-xs-12">
                <div className="important_box">
                  <h3>200+</h3>
                  <span>Members</span>
                </div>
              </div>
              <div className="col col-xs-12">
                <div className="important_box">
                  <h3>5+</h3>
                  <span>countries</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="courses" className="Courses">
        <div className="container-fluid padding_left3">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div className="box_bg">
                <div className="box_bg_img">
                  <div className="row">
                    {ed.map(({ image, name, talk }) => (
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="box_my">
                          <figure>
                            <img src={image} alt="" />
                          </figure>
                          <div className="overlay">
                            <h3>{name}</h3>
                            <p>{talk}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 border_right">
              <div className="box_text text-center">
                <div className="titlepage text-center">
                  <h2>
                    My <strong className="yellow"> Courses</strong>
                  </h2>
                </div>
                <p>
                  At{' '}
                  <span className="d-inline text-capitalize text-success">
                    {cont.name}
                  </span>
                  , we are dedicated to providing a comprehensive and diverse
                  range of courses designed to meet the academic and
                  professional aspirations of our students. Our curriculum is
                  thoughtfully developed to foster critical thinking,
                  creativity, and practical skills that prepare students for
                  success in their chosen fields.
                </p>
                <Link onClick={setShow}>Read more</Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="learn" className="learn">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="titlepage">
                <h2>
                  Learn <strong className="yellow">the Practical way</strong>
                </h2>
                <span>
                  At <span className="text-success">{cont.name}</span>, we
                  believe that the best way to learn is by doing. Our practical
                  approach to education is designed to engage, inspire, and
                  prepare you for real-world challenges. Here's why you should
                  join us . Tech skills encompass a wide range of abilities
                  related to the use of technology. These skills include
                  programming, system administration, data analysis,
                  cybersecurity, and more. Mastery of tech skills is crucial in
                  today's digital age, enabling individuals to develop software,
                  manage networks, analyze data, and protect information systems
                  effectively.
                </span>
              </div>
            </div>
          </div>
          <div className="row d-none d-md-block">
            <div className="col-md-12">
              {vidd ? (
                <div className="w-100">
                  <div className='text-center'
                    style={{
                      position: 'relative',
                      marginTop: '1.6em',
                      marginBottom: '0.9em',
                    }}
                  >
                    <iframe
                      loading="lazy"
                      className="vidd"
                      src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAGKeqzsbAI&#x2F;xozdNJZtDiwq5SuBLIqpqQ&#x2F;view?embed"
                      allowfullscreen="allowfullscreen"
                      allow="fullscreen"
                      title="welcome"
                    ></iframe>

                    {/* <a href="https://www.canva.com/design/DAGKeqzsbAI/xozdNJZtDiwq5SuBLIqpqQ/view">yess</a> */}
                  </div>

                  {/* <iframe
                    autoPlay
                    src="https://www.canva.com/design/DAGKeqzsbAI/xozdNJZtDiwq5SuBLIqpqQ/view"
                 
                    title="more"
                    className="vidd "
                    onEnded={() => {
                      setVidd(false);
                    }}
                  ></iframe> */}
                  <div>
                    <i className="fa fa-double-arrow-left text-primary"></i>
                  </div>
                </div>
              ) : (
                <div className="cpp learn_box" onClick={() => setVidd(!vidd)}>
                  <figure>
                    <img src="/images/img.jpg" alt="img" />
                  </figure>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="make">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="titlepage">
                <h2>
                  Make Your<strong className="white_colo">Self Standout</strong>
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
              <div className="make_text">
                <p>
                  At {cont.name}, we are dedicated to providing a comprehensive
                  and diverse range of courses designed to meet the academic and
                  professional aspirations of our students. Our curriculum is
                  thoughtfully developed to foster critical thinking,
                  creativity, and practical skills that prepare students for
                  success in their chosen fields.
                </p>
                <Link onClick={setShow}>Start now</Link>
              </div>
            </div>
            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
              <div className="make_img">
                <figure>
                  <img src="images/make_img.jpg" alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Other