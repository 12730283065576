import React, {
  
  useEffect,
  createContext,
  useContext,
  useState,
  
} from 'react';

import axios  from 'axios';
import Home from './components/Home';
import Admin from './components/Admin';
import Curri from './components/Curri';
import Acourses from './components/Acourses';
import About from './components/About'
import Sc from './components/Sc'
import Atrans from './components/Atrans'
import Courses from './components/Courses'
import Watch from './components/Watch'
import Coursesu from './components/Coursesu'
import Course from './components/Course'
import Prj from './components/Prj'
import Jobs from './components/Jobs'
import Swal from 'sweetalert2';


import 'animate.css/animate.min.css';

import 'bootstrap/dist/css/bootstrap.min.css';

import 'semantic-ui-css/semantic.min.css';

import {
  HashRouter as Router,
  useLocation,
  Routes,
  Route,
  useNavigate,
  Navigate
} from 'react-router-dom';
// import MyContext from './components/context';
// import Signup from './components/Signup';
import Cookies from 'js-cookie';
// index.js or App.js
import '@fortawesome/fontawesome-free/css/all.css';
import { useUser } from '../src/components/usercontext';
import { setCookie, getCookie, removeCookie } from './components/cookie';
// Get the value of the cookie with name 'myCookie'


function App() {
  const navigate = useNavigate()
  // const [warm, setWarm] = useState(false);
  // useEffect(
  //   (warm) => {
  //     const First = async () => {
  //       try {
  //         await axios.get(`${cont.api}warm`).then((res) => {
  //           if (res.data.success) {
  //             setWarm(true);
  //           } else {
  //             setWarm(false);
  //           }
  //         });
  //       } catch (error) {
  //         // Handle error if needed
  //         console.error('Error:', error);
  //       }
  //     };
  //     First();
  //   },
  //   [warm,cont]
  // );
  const { user, setUser } = useUser();
    // setUser((prev) => ({ ...prev, name: 'newsam' }));

  // alert(user.name)
  const cont = {
    name: 'MikpTech',
    fname: 'MikpTech Innovations',

    phone: '+234 8130757003',
    email: 'info@mikp.com',
    expiresin: 5,
    vl:'',
    cmail:"careers@mikptechinnovations.com",

    // api: 'http://localhost:1000/',
    // fe: 'http://localhost:3000/',
    api: 'https://mikptech.onrender.com/',
    fe: 'https://mikptech.com.ng',
    

  };
  // const [user, setUser] = useState(false);
  const hasExpired = () => {
    const nd = new Date();
    const userid = localStorage.getItem('user');
    const expiresin = localStorage.getItem('expires');

    if (userid && expiresin) {
      if (nd > new Date(expiresin)) {
        // Token has expired
        // alert('expired ' + expiresin);
        Swal.fire({
          title: `Time-out`,
          text: `pls login again `,
          icon: 'info',
          position: 'top-end',

          showConfirmButton: false,
          timer: 2500,
        });
        localStorage.removeItem('user');
        localStorage.removeItem('expires');
        setUser(null);
        // navigate('/') // Uncomment if you have a navigation function
        return true;
      } else {
        // Token is still valid
        return false;
      }
    }

    // If no user or expiresin is found
    return true;
  };

  useEffect(() => {
    const handleActivity = () => {
      

      // Retrieve the cookie value
      const userid = getCookie('userid');
      if(user && userid){
        // setUser(() => user);

        setCookie('userid', user.userid, 10);
      }
      else if(user && ! userid){
        setUser(()=>null)
        Swal.fire({
          title: `active time expired !`,
          text: `Login again : You have been inactive for too long`,
          icon: 'info',
          // position: 'top-end',

          showConfirmButton: false,
          timer: 5500,
        });
        navigate('/')
      }
      console.log('User:', user);
    };
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keypress', handleActivity);


    return () => {
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keypress', handleActivity);
      
    };
  });

  
  
  const updateUser = (newUser) => {
    setUser(newUser);
  };
  const games = [
    {
      name: 'Pick6',
      image: '/images/dice.gif',
      to: 'single',
      gameid: 'tr14246867550852',
      one: 0,
      two: 0,
      three: 0,
      four: 1.2,
      five: 1.5,
      six: 2.0,
      about:
        'Welcome to Pick6, where your fortunes await at the roll of the dice! Step into a world of thrilling uncertainty, where every click brings you closer to uncovering your fate. In this captivating game of chance, players hold their breath as they watch the dice spin, anticipation building with each passing moment. Will luck be on your side, revealing a winning combination that propels you to victory? Or will you face the challenge head-on, embracing the exhilarating risk that comes with every roll? Join us in the excitement of Pick6, where the journey is as exhilarating as the destination. Dare to roll the dice and discover what fate has in store for you!',
    },
    {
      name: 'Team Quest',
      image: '/images/ballers.gif',
      // image: '/images/droll.gif',
      to: '/double',
      gameid: 'tr1323744346786',
      about:
        'Step into the world of Adventures, where every roll counts towards victory! In this adrenaline-fueled game of chance, players embark on a thrilling journey of dice rolls, each one bringing them closer to glory. With only ten rolls at their disposal, every decision becomes crucial as they strategize and aim for the highest possible score. Will you play it safe and secure steady points, or risk it all for the chance of a game-changing roll? As the tension mounts with each toss of the dice, the excitement reaches its peak as players race against time to clinch the title of champion. Join us in After 10, where the quest for victory awaits with every roll of the dice!',
    },
  ];
  const [courses, setCourses] = useState();

  const location = useLocation();
    const { state } = location;
  const [trans, setTrans] = useState([]);

  return (
    <>
      {/* <MyContext.Provider value={cont}> */}
      <div >
        <Sc />
        <Routes>
          <Route
            path="/user/:userid"
            
            element={
              <Coursesu
                cont={cont}
                games={games}
                user={user}
                setUser={updateUser}
              />
            }
          />
          <Route
            path="/"
            element={
              <Home
                cont={cont}
                games={games}
                user={user}
                setUser={updateUser}
                courses={courses}
                setCourses={setCourses}
                exact
              />
            }
          />
          <Route
            path="/sorry"
            element={
              <Home
                cont={cont}
                games={games}
                user={user}
                setUser={updateUser}
                courses={courses}
                setCourses={setCourses}
                sorry={true}
                exact
              />
            }
          />
          <Route
            path="/prj"
            element={
              <Prj
                cont={cont}
                games={games}
                user={user}
                setUser={updateUser}
                courses={courses}
                setCourses={setCourses}
                
              />
            }
          />
          <Route
            path="/jobs"
            
            element={
              cont ? (
                <Jobs
                cont={cont}
                games={games}
                user={user}
                setUser={updateUser}
                courses={courses}
                setCourses={setCourses}
                
              />
              ):(<Navigate to='/sorry'
                
              />)
            }
          />
          <Route
            path="/courses"
            element={
              <Courses
                cont={cont}
                games={games}
                user={user}
                setUser={updateUser}
              />
            }
          />
          <Route
            path="/admin"
            element={
              user && user.admin ? (<Admin
                cont={cont}
                games={games}
                user={user}
                setUser={updateUser}
              />):(<Navigate to='/sorry'
                
              />)
            }
          />
          <Route
            path="/atrans"
            
            element={
              user && user.admin ? (
                <Atrans
                cont={cont}
                games={games}
                user={user}
                setUser={updateUser}
              />
              ):(<Navigate to='/sorry'
                
              />)
            }
          />

          <Route
            path="/course"
            
            element={
              state ? (
                <Course
                cont={cont}
                games={games}
                user={user}
                setUser={updateUser}
              />
              ):(<Navigate to='/sorry'
                
              />)
            }
          />
          <Route
            path="/curriculum"
            
            element={
              state ? (
                <Curri
                cont={cont}
                games={games}
                user={user}
                setUser={updateUser}
              />
              ):(<Navigate to='/sorry'
                
              />)
            }
          />
          <Route
            path="/watch"
            
            element={
              user ? (
                <Watch
                cont={cont}
                games={games}
                user={user}
                setUser={updateUser}
              />
              ):(<Navigate to='/sorry'
                
              />)
            }
          />
          <Route
            path="/curriculum"
            element={
              <Curri
                cont={cont}
                games={games}
                user={user}
                setUser={updateUser}
              />
            }
          />
          <Route
            path="/acourses"
            
            element={
              user && user.admin ? (
                <Acourses
                cont={cont}
                games={games}
                user={user}
                setUser={updateUser}
              />
              ):(<Navigate to='/sorry'
                
              />)
            }
          />
          <Route
            path="/about"
            element={
              <About
                cont={cont}
                games={games}
                user={user}
                setUser={updateUser}
              />
            }
          />
          <Route
            path="/*"
            element={
              <Home
                cont={cont}
                games={games}
                user={user}
                setUser={updateUser}
                courses={courses}
                setCourses={setCourses}
                error={true}
                exact
              />
            }
          />
        </Routes>
      </div>
      {/* </MyContext.Provider> */}
    </>
  );
}

export default App;
