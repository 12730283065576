import { useState } from 'react';
// import Carousel from 'react-bootstrap/Carousel';
import { Carousel } from 'react-bootstrap';
// import {ExampleCarouselImage} from 'react-bootstrap';
import { Link } from 'react-router-dom';


function ControlledCarousel({ handleShow}) {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel onSelect={handleSelect} controls={true}>
      <Carousel.Item>
        <div class="testimonial-carousel owl-carousel-item position-relative z-5">
          <img class="img-fluid" src="/img/carousel-1.jpg" alt="" />
          <div
            class="position-absolute top-0  start-0 w-100 h-100 d-flex align-items-center"
            style={{ background: 'rgba(24, 29, 56, .7)' }}
          >
            <div class="container">
              <div class="row justify-content-start">
                <div class="col-sm-10 col-lg-8">
                  <h5 class="fw-500 text-warning text-uppercase mb-3 animated slideInDown">
                    We build and train
                  </h5>
                  <h1 class="display-3 text-white animated slideInDown">
                    The Best Online Learning Platform
                  </h1>
                  <p class="fs-5 rodo text-white my-4  pb-2">
                    Learn at your pace. Our well detailed live classes are
                    automatically available to our students purchase for
                    flexibility and excellence .
                  </p>
                  <div className="mx-auto text-center ">
                    <div
                      onClick={handleShow}
                      class="btn btn-primary cpp py-md-3 px-md-5 me-3 "
                    >
                      Read More
                    </div>
                    <div
                      onClick={handleShow}
                      class="btn btn-light z-5 py-md-3 px-md-5 animated slideInRight"
                      style={{ cursor: 'pointer' }}
                    >
                      Join Now
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Carousel.Caption>
          <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption> */}
      </Carousel.Item>
      <Carousel.Item>
        <div class="testimonial-carousel owl-carousel-item position-relative">
          <img class="img-fluid" src="/img/carousel-2.jpg" alt="" />
          <div
            class="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
            style={{ background: 'rgba(24, 29, 56, .7)' }}
          >
            <div class="container">
              <div class="row justify-content-start">
                <div class="col-sm-10 col-lg-8">
                  <h5 class="text-warning text-uppercase mb-3 animated slideInDown">
                    We build and train
                  </h5>
                  <h1 class="display-3 mb-4 text-white animated slideInDown">
                    Enroll Today
                  </h1>
                  <p class="fs-5 text-white mb-4 pb-2">
                    With our online platform, you can access your lessons from
                    anywhere in the world. Say goodbye to commuting and hello to
                    flexibility. Learn at your own pace, on your own terms.
                  </p>
                  <div className="mx-auto text-center">
                    <div
                      onClick={handleShow}
                      class="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      Read More
                    </div>
                    <div
                      onClick={handleShow}
                      class="btn btn-light py-md-3 px-md-5 animated slideInRight"
                    >
                      Join Now
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Carousel.Caption>
          <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption> */}
      </Carousel.Item>
    </Carousel>
  );
}

export default ControlledCarousel;
