import React from 'react'

const Mid = () => {
  return (
    <div>
      <div class="container-xxl py-5">
        <div class="container">
          <div class="row g-4">
            <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="service-item text-center pt-3">
                <div class="p-4">
                  <i class="fa fa-3x fa-graduation-cap text-primary mb-4"></i>
                  <h5 class="mb-3">Skilled Instructors</h5>
                  <p>
                    In the realm of education, the role of instructors
                    transcends mere dissemination of knowledge. Skilled
                    instructors are the backbone of any educational institution.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
              <div class="service-item text-center pt-3">
                <div class="p-4">
                  <i class="fa fa-3x fa-globe text-primary mb-4"></i>
                  <h5 class="mb-3">Accessibility</h5>
                  <p>
                    With the increasing prevalence of online education, ensuring
                    that digital resources are accessible is critical. This
                    involves providing secured access to paid contents.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
              <div class="service-item text-center pt-3">
                <div class="p-4">
                  <i class="fas fa-3x fas fa-arrows-alt text-primary mb-4"></i>
                  {/* <i class="fas fa-arrows-alt"></i> */}
                  <h5 class="mb-3">Flexibility </h5>
                  <p>
                    Flexibility allows for the customization of educational
                    experiences to meet the unique needs, interests, and
                    learning styles of each student.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
              <div class="service-item text-center pt-3">
                <div class="p-4">
                  <i class="fa fa-3x fa-book-open text-primary mb-4"></i>
                  <h5 class="mb-3">Experiential Learning</h5>
                  <p>
                    We Incorporate experiential learning opportunities .This
                    helps students understand the relevance of their studies
                    and preparing them for future careers.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mid