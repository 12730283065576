import React ,{useEffect,useState} from 'react'
import { RevealContent, Image,ImageGroup, Reveal } from 'semantic-ui-react';
import Header from './Header'
import { Link, useNavigate } from 'react-router-dom';
import Carousel from './Caro'
import Footer from './Footer'
import Test2 from './Test2'
import WOW from 'wowjs';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Login from './Login';
import Contact from './Contact';
import ContextMenu from "./Contextm.jsx";

import Mid from './Mid';
import Other from './Other';
import axiosi from './axiosinstance';
import './all.css'
import 'animate.css/animate.min.css';
import axios from 'axios'
import Swal from 'sweetalert2';
import Spinner from 'react-bootstrap/Spinner';
import "semantic-ui-css/semantic.min.css";

import {
  ModalHeader,
  ModalDescription,
  ModalContent,
  ModalActions,
  Button,
  Icon,
  Modal,
} from 'semantic-ui-react';



const Home = ({ cont,user,setUser,sorry,error}) => {
  
  const [contextMenu, setContextMenu] = useState({
    xPos: "0px",
    yPos: "0px",
    show: false,
  });

  const handleRightClick = (e) => {
    // alert(e.pageX);
    e.preventDefault();

    setContextMenu({
      xPos: `${e.pageX}px`,
      yPos: `${e.pageY}px`,
      show: true,
    });
  };

  const handleClick = () => {
    setContextMenu({
      ...contextMenu,
      show: false,
    });
  };


  const [show, setShow] = useState(false);
  const [url, setUrl] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showp, setShowp] = useState(false);
  const [course, setCourse] = useState({});
  const handleClosep = () => setShowp(false);
  const handleShowp = () => setShowp(true);
  const [courses, setCourses] = useState([]);
  let [checkCalled, setCheck] = useState(false);
  const navigate = useNavigate();
  const tuser = {...user}
  
  


  useEffect(() => {
    const Check = async () => {
      try {
        // if(user){
        //   alert(user.name)
        // }
        await axios
          .get(user ? `${cont.api}getcoursesg/${user.userid}` : `${cont.api}getcoursesn`)
          .then((res) => {
            if (res.data.success) {
              // setFirst(false);

              if (res.data.courses) {
                //   alert(res.data.data.latestloginplayertime);
                setCourses((prev) => [ ...res.data.courses]);

                // setFirst(false);

                // navigate('/');
              }
            } else {
              navigate('/');
            }
            if(sorry){
              Swal.fire({
                title: `Oops , not allowed !`,
                text: `Sorry you are not allowed to access this route`,
                icon: "info",
                position: "top-end",

                showConfirmButton: false,
                timer: 2500,
              });
            }
            else if(error){
              Swal.fire({
                title: `Oops , That was an error!`,
                text: `The page you tried to access does not exist ! `,
                icon: "info",
                position: "top-end",

                showConfirmButton: false,
                timer: 2500,
              });
            }
          });
      } catch (error) {
        // Handle error if needed
        console.error('Error:', error);
      }
      
    };

    // Check if jwtt and first are truthy
    if (!checkCalled && user ) {
      // Call Check() if conditions are met
      Check();

      // Set the flag to true to indicate that Check() has been called
      setCheck(!checkCalled);
    }
    else if (!checkCalled) {
      Check();

      // Set the flag to true to indicate that Check() has been called
      setCheck(!checkCalled);
      if(user){
        Check();

        // Set the flag to true to indicate that Check() has been called
        setCheck(!checkCalled);
      }
    }
  }, [courses, user, checkCalled]);
  const Focus = async (cid) => {
    const cos = courses.find((el)=> el.cid === cid)
    if (cos.paid) {
      const course = courses.filter((el) => el.cid === cid);
      navigate('/watch', { state: course });
    }
    else if (cos.deployed) {
      const course = courses.filter((el) => el.cid === cid);
      navigate('/curriculum', { state: course });
    } else {
      Swal.fire({
        title: `Un-available`,
        text: ` Oops ! sorry this course is currently unavailable`,
        icon: 'info',
        position: 'top-end',

        showConfirmButton: false,
        timer: 2500,
      });
    }
    
  };
  const [firstOpen, setFirstOpen] = useState(false);

  const Buy = async (cid) => {
    let cos = courses.find((el)=> el.cid === cid)
    if(cos.deployed){
      const course = courses.filter((el) => el.cid === cid);
      const coursees = courses.filter((el) => el.cid !== cid);
      cos.onit = true
      coursees.push(cos)
      const sortedData = coursees.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
      setCourses((prev) => [...sortedData]);

      const formDataToSend = new FormData();
     
      formDataToSend.append('cid', cos.cid);
      formDataToSend.append('userid', user.userid);
      formDataToSend.append('api', cont.api);
      formDataToSend.append('fe', cont.fe);

      try {
        await axios.post(`${cont.api}buy`, formDataToSend).then((res) => {
          if (res.data.success) {
            if(res.data.bought){
              Swal.fire({
                title: `You already paid for this course`,
                text: ``,
                // icon: 'info',
                position: 'top-end',

                showConfirmButton: false,
                timer: 2500,
              });
              navigate('/user/'+user.userid)
            }
            else{
              setUrl(res.data.url);
              setCourse((prev) => ({ ...res.data.course }));
              window.location.href = res.data.url;
            }

            
            
          } else {
            const updatedData = sortedData.map((el) => ({
              ...el,
              onit: false,
            }));

            // Update the 'courses' state with the new array
            setCourses((prev) => [...updatedData]);

          }
        });
      } catch (error) {
        // Handle error if needed
        console.error('Error:', error);
       
      }

      // navigate('/curriculum', { state: course });


    }
    else{
      Swal.fire({
        title: `Un-available`,
        text: ` Oops ! sorry this course is currently unavailable`,
        icon: 'info',
        position: 'top-end',

        showConfirmButton: false,
        timer: 2500,
      });
    }
    
  };
  const [openn, setOpenn] = React.useState(false);
  const handleOpenn = () => setOpenn(true);
  const handleClosen = () => setOpenn(false);


  
  return (
    <div
      className="px-1 app-container"
      onContextMenu={handleRightClick}
      onClick={handleClick}
    >
      <ContextMenu
        xPos={contextMenu.xPos}
        yPos={contextMenu.yPos}
        show={contextMenu.show}
        user={user}
        handleAction={handleClick}
      />
      <Header
        setShow={setShow}
        handleClose={handleClose}
        handleShow={handleShow}
        home={true}
        cont={cont}
        setUser={setUser}
        user={user}
        courses={user ? true : false}
        openn={openn}
        handleClosen={handleClosen}
        handleOpenn={handleOpenn}
      />
      {user ? (
        <div>
          <div class="container-xxl py-5">
            <div class="container">
              <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h6 class="section-title bg-white text-center text-primary px-3">
                  Explore our courses
                </h6>
                <h1 class="mb-5">Select a Course</h1>
              </div>
              <div class="row g-4 justify-content-center">
                {courses && courses.length > 0 ? (
                  courses.map(
                    ({ image, name, desc, onit, cid, paid, dprice }) => (
                      <div
                        class="col-lg-4 col-md-6 wow fadeInUp"
                        data-wow-delay="0.5s"
                      >
                        <div class="course-item bg-light pb-1">
                          <div class="position-relative overflow-hidden">
                            <img class="img-fluid" src={image} alt="" />
                            <div class="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                              {paid ? (
                                <div>
                                  <div
                                    class="flex-shrink-0 btn btn-sm btn-success px-3 border-end"
                                    style={{
                                      cursor: "default",
                                      borderRadius: "30px 0 0 30px",
                                    }}
                                  >
                                    Paid{" "}
                                    <i className="fa fa-check text-light"></i>
                                  </div>
                                  <div
                                    onClick={() => {
                                      Focus(cid);
                                    }}
                                    class="flex-shrink-0 btn btn-sm btn-primary px-3"
                                    style={{ borderRadius: " 0 30px 30px 0" }}
                                  >
                                    Watch Lectures
                                  </div>
                                </div>
                              ) : (
                                <div>
                                  {onit ? (
                                    <div>
                                      <div
                                        class="flex-shrink-0 btn btn-sm btn-primary py-2 px-3 border-end"
                                        style={{
                                          borderRadius: "30px ",
                                        }}
                                      >
                                        <Spinner
                                          className="text-center fs-6"
                                          animation="border"
                                          variant="light"
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <div>
                                      <div
                                        onClick={() => {
                                          Buy(cid);
                                        }}
                                        class="flex-shrink-0 btn btn-sm btn-primary px-3 border-end"
                                        style={{
                                          borderRadius: "30px 0 0 30px",
                                        }}
                                      >
                                        Buy now
                                      </div>

                                      <div
                                        onClick={() => {
                                          Focus(cid);
                                        }}
                                        class="flex-shrink-0 btn btn-sm btn-primary px-3"
                                        style={{
                                          borderRadius: " 0 30px 30px 0",
                                        }}
                                      >
                                        Read More
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>

                          <div class="text-center p-4 pb-0">
                            <h3 class="mb-0 tida text-success fw-bolder mb-2">
                              {dprice}
                            </h3>
                            <h2 class="mb-4 tida">{name}</h2>

                            {/* <div class="mb-3">
                            <small class="fa fa-star text-primary"></small>
                            <small class="fa fa-star text-primary"></small>
                            <small class="fa fa-star text-primary"></small>
                            <small class="fa fa-star text-primary"></small>
                            <small class="fa fa-star text-primary"></small>
                            <small>(123)</small>
                          </div>
                          <h5 class="mb-4">{desc}</h5> */}
                          </div>
                        </div>
                      </div>
                    )
                  )
                ) : (
                  <div className="text-center">
                    <Spinner
                      className="text-center"
                      animation="border"
                      variant="primary"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <Offcanvas show={show} onHide={handleClose}>
            <Offcanvas.Body>
              <div style={{ marginTop: "" }} className="form-sectionh fera">
                {/* <h2 className=" text-center jbtw">
                      Login/Sign-in{' '}
                      <span>
                        {' '}
                        <i
                          onClick={handleClose}
                          closeButton className="fa fa-times"
                        ></i>
                      </span>
                    </h2> */}
                <Login
                  cont={cont}
                  setUser={setUser}
                  handleClose={handleClose}
                />
              </div>
            </Offcanvas.Body>
          </Offcanvas>
          <div class="container-fluid  p-0 ">
            <div class="tyyjj wow animate__animated animate__fadeIn owl-carousel header-carousel position-relative">
              <Carousel
                setShow={setShow}
                handleClose={handleClose}
                handleShow={handleShow}
              />
            </div>
          </div>
          <Other
            setShow={setShow}
            handleClose={handleClose}
            handleShow={handleShow}
            cont={cont}
            setUser={setUser}
            user={user}
            courses={user ? true : false}
            openn={openn}
            handleClosen={handleClosen}
            handleOpenn={handleOpenn}
          />

          <Mid />

          <div class="container-xxl py-5">
            <div class="container">
              <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h6 class="section-title bg-white text-center text-primary px-3">
                  Courses
                </h6>
                <h1 class="mb-5 tida fs-1">Popular Courses</h1>
              </div>
              <div class="row g-4 justify-content-center">
                {courses && courses.length > 0 ? (
                  courses.map(({ image, name, cid, desc, dprice }) => (
                    <div class="col-lg-4 col-md-6 wow fadeInUp">
                      <div class="course-item bg-light pb-1">
                        <div class="position-relative overflow-hidden">
                          <img class="img-fluid" src={image} alt="" />
                          <div class="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                            <div
                              onClick={() => {
                                Focus(cid);
                              }}
                              class="flex-shrink-0 btn btn-sm btn-primary px-3 border-end"
                              style={{ borderRadius: "30px 0 0 30px" }}
                            >
                              Read More
                            </div>
                            <div
                              onClick={handleShow}
                              class="flex-shrink-0 btn btn-sm btn-primary px-3"
                              style={{ borderRadius: " 0 30px 30px 0" }}
                            >
                              Register
                            </div>
                          </div>
                        </div>
                        {/* <h5 class="mb-4">{name}</h5> */}
                        <div class="text-center p-4 pb-0">
                          <h3 class="mb-0 text-success fw-bolder">{dprice}</h3>
                          <div class="mb-3">
                            <small class="fa fa-star text-primary"></small>
                            <small class="fa fa-star text-primary"></small>
                            <small class="fa fa-star text-primary"></small>
                            <small class="fa fa-star text-primary"></small>
                            <small class="fa fa-star text-primary"></small>
                            <small>(123)</small>
                          </div>
                          <h2 class="mb-4 tida">{name}</h2>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-center">
                    <Spinner
                      className="text-center"
                      animation="border"
                      variant="primary"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <Footer cont={cont} />
    </div>
  );
};

export default Home