import React from 'react'
import {Link} from 'react-router-dom'
import {ImageGroup,Image} from 'semantic-ui-react'
import Testimonial from './Testimonial.jsx'

const Footer = ({cont,user,prj}) => {
  return (
    <div className="mt-4 pt-5" style={{marginTop:"140px"}}>
      {!prj ? (
        <div>
          <Testimonial />
          <h2 className="tida fs-2 text-center pt-5 mb-5 text-capitalize">
            Where Some of our students work{' '}
          </h2>
          <ImageGroup size="small" className="mx-auto  text-center">
            <Image src="/logos/amazon-logo-removebg-preview.png" />
            <Image src="/logos/apple-logo-removebg-preview.png" />
            <Image src="/logos/cisco-logo-removebg-preview.png" />
            <Image src="/logos/dropbox-logo1.png" />
            <Image src="/logos/google-logo-removebg-preview.png" />
            <Image src="/logos/grammarly-logo-removebg-preview.png" />
            <Image src="/logos/IBM-logo-removebg-preview.png" />
            <Image src="/logos/Intel_logo-removebg-preview.png" />
            <Image src="/logos/mailchimp-logo-removebg-preview.png" />
            <Image src="/logos/meta-logo-removebg-preview.png" />
            <Image src="/logos/mobi-logo-removebg-preview.png" />
            <Image src="/logos/slack-logo-removebg-preview.png" />
            <Image src="/logos/twilio-logo-removebg-preview.png" />
            <Image src="/logos/uber-logo-removebg-preview.png" />
            <Image src="/logos/udacity-logo-removebg-preview.png" />
          </ImageGroup>
        </div>
      ) : (
        ''
      )}

      <div
        className="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-4 col-md-6">
              <h4 className="text-white mb-3">Quick Link</h4>
              <Link className="btn btn-link" to="/about">
                About Us
              </Link>
              <Link className="btn btn-link" to="/courses">
                Courses
              </Link>

              {/* <Link className="btn btn-link" to="">FAQs & Help</Link> */}
            </div>
            <div className="col-lg-4 col-md-6">
              <h4 className="text-white mb-3">Contact</h4>
              {/* <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>123 Street, New York, USA</p> */}
              <p className="mb-2">
                <i className="fa fa-phone-alt me-3"></i>
                {cont.phone}
              </p>
              <p className="mb-2">
                <i className="fa fa-envelope me-3"></i>i{cont.email}
              </p>
            </div>
            <div className="col-lg-4 col-md-6">
              <h4 className="text-white mb-3">Gallery</h4>
              <div className="row g-2 pt-2">
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-1"
                    src="img/course-1.jpg"
                    alt=""
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-1"
                    src="img/course-2.jpg"
                    alt=""
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-1"
                    src="img/course-3.jpg"
                    alt=""
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-1"
                    src="img/course-2.jpg"
                    alt=""
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-1"
                    src="img/course-3.jpg"
                    alt=""
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-1"
                    src="img/course-1.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            {/* <div className="col-lg-3 col-md-6">
              <h4 className="text-white mb-3">Newsletter</h4>
              <p>
                Get Newsletters and updates on our courses and and seminars.
              </p>
              <div
                className="position-relative mx-auto"
                style={{ maxWidth: '400px' }}
              >
                <input
                  className="form-control border-0 w-100 py-3 ps-4 pe-5"
                  type="text"
                  placeholder="Your email"
                />
                <button
                  type="button"
                  className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2"
                >
                  SignUp
                </button>
              </div>
            </div> */}
          </div>
        </div>
        <div className="container">
          <div className="copyright">
            <div className="row">
              <div className="col-12 text-center text-center mb-3 mb-md-0">
                &copy; <h3 className="border-bottom d-inline">{cont.fname}</h3>{' '}
                All Right Reserved. Designed By{' '}
                <Link className="border-bottom" to="/">
                  {cont.fname}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <a href="#head" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="fa fa-arrow-up"></i></a> */}
    </div>
  );
}

export default Footer